
  import AeqSubtitle from '~/components/AeqSubtitle'
  import AeqTextBlock from '~/components/AeqTextBlock'
  import AeqSeparator from '~/components/AeqSeparator'
  import AeqDateField from '~/components/AeqDateField'
  import AeqDocumentList from '~/components/AeqDocumentList'
  import AeqFileField from '~/components/AeqFileField'
  import AeqPhoneField from '~/components/AeqPhoneField'
  import AeqSwitchField from '~/components/AeqSwitchField'
  import AeqSelectField from '~/components/AeqSelectField'
  import AeqCountriesField from '~/components/AeqCountriesField'
  import AeqSelectionControls from '~/components/AeqSelectionControls'
  import AeqSpan from '~/components/AeqSpan.vue'
  import FormElement from '~/components/FormElement'
  import AeqInputSearcherField from '~/components/AeqInputSearcherField'
  import AeqFillerField from '~/components/AeqFillerField'
  import AeqDASigner from '~/components/AeqDASigner'

  export default {
    name: `FormElement`,
    components: {
      /* eslint-disable vue/no-unused-components */
      AeqSubtitle,
      AeqTextBlock,
      AeqSeparator,
      AeqDateField,
      AeqDocumentList,
      AeqFileField,
      AeqPhoneField,
      AeqSwitchField,
      AeqSelectField,
      AeqCountriesField,
      AeqSelectionControls,
      AeqSpan,
      FormElement,
      AeqInputSearcherField,
      AeqFillerField,
      AeqDASigner,
    },
    props: {
      dataFormElement: {
        type: Object,
        default: null
      },
      dataAnwser: {
        type: Object,
        default: null
      },
      token: {
        type: String,
        default: null
      },
      value: {
        type: null,
        default: null
      },
      display: {
        type: Boolean,
        default: true
      },
      readonly: {
        type: Boolean,
        default: false
      },
      joinedDocuments: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        innerValue:
          (this.dataFormElement.type || this.dataFormElement.form_element || ``) === `document_list`
            ? this.joinedDocuments
            : this.value,
        inputVeeRules: {},
        inputTitle: ``,
        description: ``,
        inputType: `v-text-field`,
        bindedProps: {},
        validationMode: this.readonly ? `passive` : `aggressive`,
        xsSize: 12,
        smSize: 12,
        mdSize: 6,
        lgSize: 4,
        xlSize: 3,
        ready: false,
        hideDetails: false,
        formDataElement: true,
        typeDocumentForBlur: [`text_input`, `text_area`, `number_input`, `email_input`, `phone_input`, `url_input`]
      }
    },
    computed: {
      flags() {
        return this.$refs.validator.flags
      }
    },
    watch: {
      value: {
        deep: true,
        handler(newValue) {
          this.innerValue = newValue
        }
      },
      joinedDocuments: {
        deep: true,
        handler(newValue) {
          const type = this.dataFormElement.type || this.dataFormElement.form_element || ``
          if (type === `document_list`) {
            this.innerValue = newValue
          }
        }
      },
      readonly(newValue) {
        this.$refs.component.$attrs.disabled = newValue
        this.setDisableAndReadOnly()
        this.$refs.component.$forceUpdate()
      }
    },
    mounted() {
      if (!!this.dataFormElement) {
        this.setDense()
        this.setDisableAndReadOnly()
        this.setTypes()
        this.setAnswersList()
        this.setLabel()
        this.setDescription()
        this.setOptions()
        this.setHint()
        this.setRequirements()
      }
      this.ready = true
    },
    methods: {
      getInput(value) {
        if (!this.readonly) {
          if (!!this.dataFormElement?.options?.uppercase?.value) {
            value = value.toUpperCase()
          }
          this.innerValue = value

          !this.typeDocumentForBlur.includes(this.dataFormElement.type) && this.$emit(`input`, value)

          this.$emit(`blur-question`, value)
        }
      },
      getErrors() {
        return this.$refs.validator.errors
      },
      setDense() {
        this.bindedProps.dense = true
      },
      setTypes() {
        const type = this.dataFormElement.type || this.dataFormElement.form_element || ``
        switch (type) {
          case `subtitle`:
            this.inputType = `aeq-subtitle`
            break
          case `text_block`:
            this.inputType = `aeq-text-block`
            break
          case `separator`:
            this.inputType = `aeq-separator`
            break
          case `document`:
            this.setDocument()
            break
          case `document_list`:
            this.setDocumentList()
            break
          case `textarea`:
          case `text_area`:
            this.inputType = `v-textarea`
            break
          case `phone`:
            this.inputType = `aeq-phone-field`
            this.inputVeeRules.phoneNumber = true
            break
          case `date`:
            this.setDate()
            break
          case `switch`:
            this.inputType = `aeq-switch-field`
            break
          case `selection_controls`:
            this.setSelectionControls()
            this.setReturnObject()
            this.bindedProps.initial = this.dataFormElement.options?.initial?.value
            break
          case `please_sign`:
            this.inputType = ``
            break
          case `select`:
            this.inputType = `aeq-select-field`
            this.bindedProps.initial = this.dataFormElement.options?.initial?.value
            this.setReturnObject()
            break
          case `country`:
            this.inputType = `AeqCountriesField`
            this.setReturnObject()
            break
          case `input_number`:
            this.inputType = `v-text-field`
            this.inputVeeRules.positiveNumbers = true
            this.hideDetails = true
            break
          case `input_searcher`:
            this.bindedProps.searchUrl = this.dataFormElement.options?.search_url?.value
            this.inputType = `AeqInputSearcherField`
            this.setReturnObject()
            break
          case `filler`:
            this.inputType = `AeqFillerField`
            break
          case `da_signer`:
            this.bindedProps.daCode = this.dataFormElement.options?.da_code?.value
            this.inputType = `AeqDASigner`
            this.setDaSigner()
            break
          case `span`:
            this.inputType = `AeqSpan`
            this.formDataElement = false
            break
          case `email`:
            this.inputVeeRules.email = true
          // fallthrough
          case `rate`:
          case `text`:
          default:
            this.inputType = `v-text-field`
        }
      },
      setAnswersList() {
        const { answers_list: answersList } = this.dataFormElement
        if (!!answersList) {
          this.bindedProps.items = answersList
        }
      },
      setLabel() {
        const { label } = this.dataFormElement
        if (!!label) {
          this.bindedProps.label = label
          this.inputTitle = this.dataFormElement.label
        }
      },
      setDescription() {
        const { description } = this.dataFormElement
        if (!!description) {
          this.bindedProps.description = description
          this.description = description
        }
      },
      setOptions() {
        const { options } = this.dataFormElement
        if (!!options) {
          const { xsSize, smSize, mdSize, lgSize, xlSize, size, multiple, value } = options
          this.xsSize = xsSize?.value || this.xsSize
          this.smSize = smSize?.value || this.smSize
          this.mdSize = mdSize?.value || size.value || this.mdSize
          this.lgSize = lgSize?.value || size.value || this.lgSize
          this.xlSize = xlSize?.value || size.value || this.xlSize

          if (!!multiple) {
            this.bindedProps.multiple = this.bindedProps.deletableChips = this.bindedProps.smallChips = multiple.value
          }
          if (!!value) {
            this.bindedProps.length = value.max
            this.bindedProps.halfIncrements = value.halfIncrements
          }
        }
      },
      setHint() {
        const { hint } = this.dataFormElement
        if (!!hint) {
          this.bindedProps.hint = hint
          this.bindedProps.persistentHint = true
        }
      },
      setRequirements() {
        const { requirements, is_question: isQuestion } = this.dataFormElement
        if (!!requirements) {
          const { required, is_required_if: isRequiredIf } = requirements
          if (required && !!isQuestion) {
            this.inputVeeRules.required = true
          }
          if (!!isRequiredIf) {
            for (const qName in isRequiredIf) {
              const data = isRequiredIf[qName]
              if (!!data) {
                this.inputVeeRules.required_if = { target: qName, values: data.answers_condition }
              }
            }
          }
          this.bindedProps.requirements = requirements
        }
      },
      setDisableAndReadOnly() {
        const { options } = this.dataFormElement

        this.bindedProps.disabled = this.readonly

        this.dataFormElement.name === `candidate_chase` && console.log(`infos`, this.readonly, options)

        if (!this.readonly && !!options) {
          const { disabled, readonly } = options
          const readonlyValue = !!readonly?.value ? readonly.value : typeof readonly === `boolean` ? readonly : false
          this.bindedProps.readonly = readonlyValue

          const disableValue = !!disabled?.value ? disabled.value : typeof disabled === `boolean` ? disabled : false
          this.bindedProps.disabled = disableValue

          if (!!this?.$refs?.component?.$attrs) {
            this.$refs.component.$attrs.disabled = disableValue
            this.$refs.component.$attrs.readonly = readonlyValue
          }

          this.dataFormElement.name === `candidate_chase` &&
            console.log(`infos ici`, this.bindedProps.disabled, this.bindedProps.readonly)
        }
      },
      setDocument() {
        const { showSize, small, listLight, requirements } = this.dataFormElement
        this.validationMode = `passive`
        this.inputType = `aeq-file-field`

        this.bindedProps.showSize = showSize?.value || true
        this.bindedProps.small = small?.value || true
        this.bindedProps.listLight = listLight?.value || false
        if (requirements.required) {
          this.inputVeeRules.requireDocAEQ = true
        }
        if (!!requirements.extensions && requirements.extensions.length > 0) {
          this.inputVeeRules.formatDocAEQ = { extensions: requirements.extensions }
        }
        this.inputVeeRules.sizeDocAEQ = { size: requirements.size || this.$config.public.default_file_limit }
      },
      setDaSigner() {
        const requirements = this.dataFormElement.requirements
        if (requirements.required) {
          this.inputVeeRules.requireDocAEQ = true
        }
      },
      setDocumentList() {
        const { options } = this.dataFormElement
        this.inputType = `aeq-document-list`

        this.bindedProps.detailed = typeof options?.detailed?.value === `boolean` ? options.detailed.value : true
        this.bindedProps.light = typeof options?.light?.value === `boolean` ? options.light.value : true
      },
      setDate() {
        const { options } = this.dataFormElement
        this.inputType = `aeq-date-field`

        this.bindedProps.locale = this.$auth.user?.lang || `en`
        this.bindedProps.type = options?.type ? options.type : `date`

        options?.limits?.min &&
          typeof options.limits.min === `number` &&
          (this.inputVeeRules.before = {
            limit: new Date(new Date().setFullYear(new Date().getFullYear() - options.limits.min))
          })
        this.inputVeeRules.formatDate = true
        this.inputVeeRules.consistentDate = true
      },
      setSelectionControls() {
        const { options } = this.dataFormElement || {}
        const { multiple, column } = options || {}
        this.inputType = `aeq-selection-controls`

        this.bindedProps.column =
          typeof column === `boolean` ? column : typeof column?.value === `boolean` ? column.value : false
        this.bindedProps.multiple =
          typeof multiple === `boolean` ? multiple : typeof multiple?.value === `boolean` ? multiple.value : false
      },
      async cleanValidation(event) {
        if (!this.readonly) {
          if (this.dataFormElement.type === `document`) {
            this.$refs.validator.syncValue(this.$refs.component.getAllValues())
          }
          await this.$refs.validator.validate()
        }

        this.$emit(`blur-question`, event)

        !!this.typeDocumentForBlur.includes(this.dataFormElement.type) &&
          this.$emit(`input`, event?.target?.value || ``)
      },
      getAnswerData(configDetail) {
        return this.dataAnwser.configuration_details.find(
          (configurationDetail) => configurationDetail.name === configDetail.name
        );
      },
      setReturnObject() {
        this.bindedProps.returnObject = Object.keys(this.$attrs).includes(`return-object`)
      }
    }
  }
